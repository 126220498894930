<template>
  <div>
    <div class="Broadcast">
      <vx-card title="Broadcast Gönder">
        <BroadcastForm></BroadcastForm>
      </vx-card>
    </div>
    <div class="Broadcast-list mt-8">
      <vx-card title="Gönderilenler">
        <BroadcastList :list="list"></BroadcastList>
      </vx-card>
    </div>
  </div>
</template>

<script>
import BroadcastForm from '@/components/broadcast/BroadcastForm.vue';
import BroadcastList from '@/components/broadcast/BroadcastList.vue';
import { usePageRefresh } from '@/mixins';
import { mapGetters, mapActions } from 'vuex';
export default {
  components: {
    BroadcastList,
    BroadcastForm
  },
  mixins: [usePageRefresh],
  data() {
    return {
      subject: '',
      text: '',
      pushNotification: false
    };
  },
  computed: mapGetters('broadcast', ['list']),
  methods: {
    ...mapActions('broadcast', ['getAll']),
    async fetchData() {
      this.$vs.loading();
      await this.getAll().finally(() => {
        this.$vs.loading.close();
      });
    }
  },

  async created() {
    this.fetchData();
  }
};
</script>

<style>
.element--textarea {
  min-height: 100px;
  height: 200px;
  padding: 10px;
  color: inherit;
  font-size: 14px;
}
.border-alpha-2 {
  border-color: rgba(0, 0, 0, 0.2) !important;
}
.focus-none input:focus {
  box-shadow: none !important;
}
.form-element-outline:focus {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(var(--vs-primary), 1) !important;
}
</style>
