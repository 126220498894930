var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          attrs: {
            stripe: "",
            multiple: false,
            sst: true,
            "max-items": 50,
            data: _vm.list,
            noDataText: ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return [
                  _c(
                    "tbody",
                    _vm._l(data, function(tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c(
                            "vs-td",
                            { staticClass: "min-w-32", attrs: { id: "topic" } },
                            [_vm._v("\n            -\n            ")]
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass:
                                "break-word max-w-sm overflow-auto min-w-64",
                              attrs: { id: "message" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "whitespace-pre-wrap " },
                                [_vm._v(_vm._s(tr.message))]
                              )
                            ]
                          ),
                          _c("vs-td", { attrs: { id: "createdAt" } }, [
                            _c("p", { staticClass: "font-medium w-24" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(tr.createdAt, "DD-MM-YYYY")
                                  ) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(tr.createdAt, "HH:mm")
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _c("vs-td", { attrs: { id: "user" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  tr.whoInitializeBroadcastMessageUser.username
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { attrs: { "sort-key": "topic" } }, [_vm._v("Konu")]),
              _c("vs-th", { attrs: { "sort-key": "message" } }, [
                _vm._v("Mesaj")
              ]),
              _c("vs-th", { attrs: { "sort-key": "createdAt" } }, [
                _vm._v("Gönderim Tarihi")
              ]),
              _c("vs-th", { attrs: { "sort-key": "user" } }, [
                _vm._v("Gönderen")
              ])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }