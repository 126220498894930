import { render, staticRenderFns } from "./Broadcast.vue?vue&type=template&id=3ce46702&"
import script from "./Broadcast.vue?vue&type=script&lang=js&"
export * from "./Broadcast.vue?vue&type=script&lang=js&"
import style0 from "./Broadcast.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mac/Documents/onedio-repos/onedio-dio-admin-front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ce46702')) {
      api.createRecord('3ce46702', component.options)
    } else {
      api.reload('3ce46702', component.options)
    }
    module.hot.accept("./Broadcast.vue?vue&type=template&id=3ce46702&", function () {
      api.rerender('3ce46702', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/broadcast/Broadcast.vue"
export default component.exports