<template>
  <div class="max-w-md text-gray-900">
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Konu</span>
        </div>
        <vs-input class="w-full" label="" v-model="broadcast.subject" />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Mesaj</span>
        </div>
        <textarea
          v-model="broadcast.message"
          maxlength="3000"
          class="element element--textarea w-full rounded border-alpha-2 form-element-outline"
        ></textarea>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-checkbox disabled class="inline-flex cursor-not-allowed" v-model="broadcast.pushNotification">Push Gönder</vs-checkbox>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button @click="handleSendBroadcast" :disabled="isButtonDisabled">Gönder</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';
export default {
  data: () => ({
    broadcast: {},
    isButtonDisabled: false
  }),
  watch: {
    newMessage: {
      immediate: true,
      handler() {
        this.broadcast = _.cloneDeep(this.newMessage);
      }
    }
  },
  computed: {
    ...mapState('broadcast', ['newMessage']),
    isValid() {
      const { subject, message } = this.broadcast;
      return subject || message;
    },
    payload() {
      const { subject, message, pushNotification } = this.broadcast;
      const newMessage = `${subject}
${message}
`; // enter karakteri icin
      return { subject, message: newMessage, pushNotification };
    }
  },
  methods: {
    ...mapActions('broadcast', ['getAll', 'sendBroadcast', 'resetNewMessage']),
    ...mapMutations('broadcast', ['setNewMessage']),
    async handleSendBroadcast() {
      if (!this.isValid) {
        alert('Lütfen Tüm alanları doldurun');
        return;
      }
      this.isButtonDisabled = true;
      this.setNewMessage(this.payload);
      await this.sendBroadcast();
      this.resetNewMessage();
      this.isButtonDisabled = false;
    }
  }
};
</script>

<style></style>
