var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "Broadcast" },
      [
        _c(
          "vx-card",
          { attrs: { title: "Broadcast Gönder" } },
          [_c("BroadcastForm")],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "Broadcast-list mt-8" },
      [
        _c(
          "vx-card",
          { attrs: { title: "Gönderilenler" } },
          [_c("BroadcastList", { attrs: { list: _vm.list } })],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }